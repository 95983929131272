import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112')
];

export const server_loads = [2,3,4,10,5,6];

export const dictionary = {
		"/(base)/(web)": [14,[2,3]],
		"/(base)/(web)/(authenticated)/(admin)/admin": [15,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/accounts": [16,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/accounts/[id]": [~17,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/accounts/[id]/bulk-clone": [18,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/connect/providers": [19,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/connect/providers/[id]": [20,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/portfolios/[id]": [21,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/portfolios/[id]/bulk-clone": [22,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/portfolios/[id]/bulk-update": [23,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/properties": [24,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/properties/[id]": [25,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/properties/[id]/clone": [26,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/properties/[id]/debug": [27,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/properties/[id]/update": [~28,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/purgeRedis": [~29,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/queue": [30,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/sync": [31,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/temp": [32,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/tools": [33,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/tools/nyse-parser": [34,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/tools/pdf-reader": [35,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/tools/statement-parser": [36,[2,4]],
		"/(base)/(web)/(authenticated)/billing": [59,[2,3]],
		"/(base)/(web)/(authenticated)/billing/checkout": [~60,[2,3]],
		"/(base)/(web)/(authenticated)/billing/checkout/bypass": [~61,[2,3]],
		"/(base)/(web)/(authenticated)/billing/checkout/first-free": [~62,[2,3]],
		"/(base)/(web)/(authenticated)/billing/checkout/success": [~63,[2,3]],
		"/(base)/(web)/(authenticated)/connect/providers": [64,[2,3]],
		"/(base)/(web)/(authenticated)/connect/providers/[id]": [65,[2,3]],
		"/(base)/(web)/contact-us": [79,[2,3]],
		"/(base)/(web)/glossary": [80,[2,3]],
		"/(base)/(web)/login": [81,[2,3]],
		"/(base)/(web)/logout": [82,[2,3]],
		"/(base)/(web)/map": [83,[2,3]],
		"/(base)/(web)/method": [84,[2,3]],
		"/(base)/(web)/(authenticated)/my-portfolios": [66,[2,3]],
		"/(base)/(web)/(authenticated)/my-portfolios/add-properties": [75,[2,3]],
		"/(base)/(web)/(authenticated)/my-portfolios/add": [74,[2,3]],
		"/(base)/(web)/(authenticated)/my-portfolios/[id]": [67,[2,7]],
		"/(base)/(web)/(authenticated)/my-portfolios/[id]/print/all": [68,[2,9]],
		"/(base)/(web)/(authenticated)/my-portfolios/[id]/print/dashboard": [69,[2,8]],
		"/(base)/(web)/(authenticated)/my-portfolios/[id]/print/properties": [70,[2,8]],
		"/(base)/(web)/(authenticated)/my-portfolios/[id]/print/well-interest": [71,[2,8]],
		"/(base)/(web)/(authenticated)/my-portfolios/[id]/well-info": [72,[2,7]],
		"/(base)/(web)/(authenticated)/my-portfolios/[id]/well-interest": [73,[2,7]],
		"/(base)/(web)/privacy-policy": [109,[2,3]],
		"/(base)/(web)/(authenticated)/profile": [~76,[2,3]],
		"/(base)/(web)/p/[id]": [~85,[2,10]],
		"/(base)/(web)/p/[id]/component/map": [86,[2,11]],
		"/(base)/(web)/p/[id]/details/beyond-producing-wells": [87,[2,10]],
		"/(base)/(web)/p/[id]/details/geographic-info": [88,[2,10]],
		"/(base)/(web)/p/[id]/details/lease-estimate": [89,[2,10]],
		"/(base)/(web)/p/[id]/details/monthly-revenue": [90,[2,10]],
		"/(base)/(web)/p/[id]/details/new-drilling": [91,[2,10]],
		"/(base)/(web)/p/[id]/details/new-permits": [92,[2,10]],
		"/(base)/(web)/p/[id]/details/new-wells": [93,[2,10]],
		"/(base)/(web)/p/[id]/details/property-info": [94,[2,10]],
		"/(base)/(web)/p/[id]/details/sales-estimate": [95,[2,10]],
		"/(base)/(web)/p/[id]/details/well-information": [96,[2,10]],
		"/(base)/(web)/p/[id]/print-manager": [108,[2,10]],
		"/(base)/(web)/p/[id]/print/all": [97,[2,13]],
		"/(base)/(web)/p/[id]/print/dashboard": [~98,[2,12]],
		"/(base)/(web)/p/[id]/print/details/beyond-producing-wells": [99,[2,12]],
		"/(base)/(web)/p/[id]/print/details/lease-estimate": [100,[2,12]],
		"/(base)/(web)/p/[id]/print/details/monthly-revenue": [101,[2,12]],
		"/(base)/(web)/p/[id]/print/details/new-drilling": [102,[2,12]],
		"/(base)/(web)/p/[id]/print/details/new-permits": [103,[2,12]],
		"/(base)/(web)/p/[id]/print/details/new-wells": [104,[2,12]],
		"/(base)/(web)/p/[id]/print/details/sales-estimate": [105,[2,12]],
		"/(base)/(web)/p/[id]/print/details/well-information": [106,[2,12]],
		"/(base)/(web)/p/[id]/print/well-interest": [~107,[2,12]],
		"/(base)/(web)/reset-password": [110,[2,3]],
		"/(base)/(web)/signup": [111,[2,3]],
		"/(base)/(web)/(authenticated)/(enterprise)/speculator": [37,[2,3]],
		"/(base)/(web)/terms-conditions": [112,[2,3]],
		"/(base)/(web)/(authenticated)/(enterprise)/tract-from-unit": [38,[2,3]],
		"/(base)/(web)/(authenticated)/(enterprise)/tract-from-unit/excel": [39,[2,3]],
		"/(base)/(web)/(authenticated)/wells": [77,[2,3]],
		"/(base)/(web)/(authenticated)/wells/build-tracts": [78,[2,3]],
		"/(base)/(web)/(authenticated)/[page=tracts]": [40,[2,3]],
		"/(base)/(web)/(authenticated)/[page=tracts]/quick-add": [58,[2,3]],
		"/(base)/(web)/(authenticated)/[page=tracts]/[id]": [~41,[2,3,5]],
		"/(base)/(web)/(authenticated)/[page=tracts]/[id]/build-estimate": [42,[2,3,5]],
		"/(base)/(web)/(authenticated)/[page=tracts]/[id]/geographic-info": [43,[2,3,5]],
		"/(base)/(web)/(authenticated)/[page=tracts]/[id]/geom/update": [44,[2,3,5]],
		"/(base)/(web)/(authenticated)/[page=tracts]/[id]/pecan-estimate/[dto_id]": [~45,[2,6]],
		"/(base)/(web)/(authenticated)/[page=tracts]/[id]/pecan-estimate/[dto_id]/beyond-producing-wells": [46,[2,6]],
		"/(base)/(web)/(authenticated)/[page=tracts]/[id]/pecan-estimate/[dto_id]/build-estimate": [47,[2,6]],
		"/(base)/(web)/(authenticated)/[page=tracts]/[id]/pecan-estimate/[dto_id]/lease-estimate": [48,[2,6]],
		"/(base)/(web)/(authenticated)/[page=tracts]/[id]/pecan-estimate/[dto_id]/monthly-revenue": [49,[2,6]],
		"/(base)/(web)/(authenticated)/[page=tracts]/[id]/pecan-estimate/[dto_id]/new-drilling": [50,[2,6]],
		"/(base)/(web)/(authenticated)/[page=tracts]/[id]/pecan-estimate/[dto_id]/new-permits": [51,[2,6]],
		"/(base)/(web)/(authenticated)/[page=tracts]/[id]/pecan-estimate/[dto_id]/new-wells": [52,[2,6]],
		"/(base)/(web)/(authenticated)/[page=tracts]/[id]/pecan-estimate/[dto_id]/print-manager": [53,[2,6]],
		"/(base)/(web)/(authenticated)/[page=tracts]/[id]/pecan-estimate/[dto_id]/sales-estimate": [54,[2,6]],
		"/(base)/(web)/(authenticated)/[page=tracts]/[id]/pecan-estimate/[dto_id]/well-information": [55,[2,6]],
		"/(base)/(web)/(authenticated)/[page=tracts]/[id]/property-info": [~56,[2,3,5]],
		"/(base)/(web)/(authenticated)/[page=tracts]/[id]/well-interest": [~57,[2,3,5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';